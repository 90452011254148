export const insertBuildingLevelVisuals = [
  {
    name: "Demo",
    markdown: `![Insert building level GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const insertBuildingLevels = {
  order: 30,
  name: "Insert building levels",
  keywords: "floors stories story vertical above below",
  subcategory: "Properties",
  markdown: `# Insert building levels

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  In Tower Hunt, a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} can display {% inlineRouterLink articleId="add-a-land-covering" %}buildings{% /inlineRouterLink %} that have {% inlineRouterLink articleId="add-building-levels" %}vertical levels{% /inlineRouterLink %}. When necessary, you can insert more levels above or below an existing level.

  ## Steps

  {% tablessContainer marginStyles="mb-4" %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-building-levels" %}building level{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="insertLandCoveringLevels" /%}.
  4. In the pop-up that appears above the button, type in the number of floors to add above and below the current level.
  5. In the pop-up that appears above the button, click **Save**. The building now displays the additional floors.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$insertBuildingLevelVisuals /%}
  `,
};
